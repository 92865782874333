<template lang="html">
	<v-footer
		:class="{
			'pa-4': $vuetify.breakpoint.mdAndUp,
			'pt-1': $vuetify.breakpoint.smAndDown,
		}"
		:height="footer_height"
	>
		<v-layout row wrap align-center>
			<v-flex md5 xs12>
				<div
					:class="{
						'text-left body-1 ml-3': $vuetify.breakpoint.mdAndUp,
						'text-center body-1': $vuetify.breakpoint.smAndDown,
						'text-left caption': $vuetify.breakpoint.lg,
					}"
				>
					<span class="key hidden-sm-and-down"
						>Graphics and lead scientists:&nbsp;</span
					>
					<a
						target="_blank"
						href="https://usys.ethz.ch/en/people/profile.nicolas-gruber.html"
					>
						Nicolas Gruber </a
					>&amp;
					<a
						target="_blank"
						href="https://up.ethz.ch/people/person-detail.MjU3NTQ5.TGlzdC8xNzUxLC0zMDYxNTA1MjU=.html"
					>
						Luke Gregor
					</a>
					<span class="value hidden-lg-and-down"
						>,
						<a href="https://up.ethz.ch/" target="_blank">ETH Zürich</a>.</span
					>
				</div>
				<div
					:class="{
						'text-left ml-3': $vuetify.breakpoint.mdAndUp,
						'text-center': $vuetify.breakpoint.smAndDown,
						'text-left caption': $vuetify.breakpoint.lg,
					}"
				>
					<span class="key">Data:&nbsp;</span>
					<span
						><a
							href="https://essd.copernicus.org/articles/13/777/2021/"
							target="_blank"
							>OceanSODA-ETHZ</a
						></span
					>
				</div>
			</v-flex>
			<v-flex md5 offset-md2 xs-12>
				<div
					:class="{
						'text-right subheading mr-4': $vuetify.breakpoint.mdAndUp,
						'text-center subheading': $vuetify.breakpoint.smAndDown,
						'text-right caption': $vuetify.breakpoint.lg,
					}"
				>
					<span class="key">Website:&nbsp;</span>
					<a target="_blank" href="https://www.the-iea.org/">
						<img
							src="@/assets/IEA_favicon.png"
							class="iea_favicon"
							height="30"
							alt="Institute for Environmental Analytics logo"
						/>
						<span class="hidden-sm-and-down"
							>Institute for Environmental Analytics</span
						>
						<span class="hidden-md-and-up">IEA</span>
					</a>
				</div>
				<div
					:class="{
						'text-right subheading mr-4': $vuetify.breakpoint.mdAndUp,
						'text-center subheading': $vuetify.breakpoint.smAndDown,
						'text-right caption': $vuetify.breakpoint.lg,
					}"
				>
					<span class="key">Website funding:&nbsp;</span>
					<a target="_blank" href="https://www.up.ethz.ch/">
						<img
							src="@/assets/img/ethz_logo.svg"
							class="iea_favicon"
							height="26"
							alt="ETH Zürich logo"
						/>
					</a>
				</div>
			</v-flex>
		</v-layout>
	</v-footer>
</template>

<script>
export default {
	computed: {
		footer_height() {
			let height = ''
			switch (this.$vuetify.breakpoint.name) {
				case 'xs':
				case 'sm':
				case 'md':
					height = 130
					break
				case 'lg':
					height = 70
					break
				case 'xl':
					height = 70
					break
			}
			return height
		},
	},
}
</script>

<style lang="css" scoped>
.iea_favicon {
	vertical-align: middle;
}
.key {
	color: gray;
}
.value {
	color: white;
}

.text-center {
	font-size: 0.8rem !important;
}
</style>
