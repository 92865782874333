import '@mdi/font/css/materialdesignicons.css'
import 'babel-polyfill'
import Vue from 'vue'

import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
// import 'vuetify/src/stylus/app.styl'

import { createSimpleTransition } from 'vuetify/lib/components/transitions/createTransition'

Vue.use(Vuetify)

const slideTransition = createSimpleTransition('my-transition')

Vue.component('my-transition', slideTransition)

import VueRouter from 'vue-router'
import App from './App.vue'
import { routes } from './routes.js'

Vue.use(VueRouter)

const router = new VueRouter({
	// base: process.env.NODE_ENV === 'production' ? '/showyournaturestripes/' : '/',
	base: '/',
	routes: routes,
	mode: 'history',
})

Vue.config.productionTip = false

import menuTree from './data/regionData.js'
Vue.prototype.$store = Vue.observable({
	menuTree,
	selectedLmeBasin: 'basin',
	selectedOcean: 'globalocean',
	selectedRegion: 'entirebasin',
	selectedMeasure: 'ph',
	selectedVariant: 's',
})

new Vue({
	router: router,
	render: (h) => h(App),
	vuetify: new Vuetify({
		icons: { iconfont: 'mdi' },
		theme: {
			dark: true,
			themes: {
				light: {
					primary: '#1976D2',
					secondary: '#424242',
					accent: '#82B1FF',
					error: '#FF5252',
					info: '#2196F3',
					success: '#4CAF50',
					warning: '#FFC107',
				},
			},
		},
	}),
}).$mount('#app')
