import Stripes from './components/Stripes.vue'
import Faq from './components/Faq.vue'

export const routes = [
	{ path: '/faq', name: 'faq', component: Faq },
	{
		path: '/:stripeType?/:pH?/:lmebasin?/:ocean?/:region?',
		name: 'stripes',
		component: Stripes,
	},
]
