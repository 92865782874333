<template lang="html">
	<v-container>
		<v-layout wrap>
			<v-flex sm12>
				<v-select
					v-model="selectedLmeBasin"
					:items="availableLmeBasins"
					label="Class"
				></v-select>
			</v-flex>
			<v-flex sm12>
				<v-select
					v-if="availableOceans.length"
					v-model="selectedOcean"
					:items="availableOceans"
					label="Ocean"
				></v-select>
			</v-flex>
			<v-flex v-if="availableRegions.length > 1" sm12>
				<v-select
					v-model="selectedRegion"
					:items="availableRegions"
					label="Region"
				></v-select>
			</v-flex>
		</v-layout>
	</v-container>
</template>
<script>
export default {
	data() {
		return {
			localLmeBasin: this.$store.selectedLmeBasin,
		}
	},
	computed: {
		availableLmeBasins() {
			return Object.entries(this.$store.menuTree).map((e) => ({
				value: e[0],
				text: e[1].name,
			}))
		},
		availableOceans() {
			if (!this.selectedLmeBasin) {
				return []
			}
			const lmeBasinObj = this.$store.menuTree[this.selectedLmeBasin]
			if (!lmeBasinObj || !lmeBasinObj.hasOwnProperty('children')) {
				return []
			}
			return Object.entries(lmeBasinObj.children).map((e) => ({
				value: e[0],
				text: e[1].name,
			}))
		},
		availableRegions() {
			if (!this.selectedLmeBasin || !this.selectedOcean) {
				return []
			}
			const lmeBasinObj = this.$store.menuTree[this.selectedLmeBasin]
			if (!lmeBasinObj || !lmeBasinObj.hasOwnProperty('children')) {
				return []
			}
			const oceanObj = lmeBasinObj.children[this.selectedOcean]
			if (!oceanObj || !oceanObj.hasOwnProperty('children')) {
				return []
			}
			return Object.entries(oceanObj.children).map((e) => ({
				value: e[0],
				text: e[1].name,
			}))
		},
		selectedLmeBasin: {
			get() {
				return this.$store.selectedLmeBasin
			},
			set(lmeBasin) {
				this.$store.selectedLmeBasin = lmeBasin
				this.localLmeBasin = lmeBasin
			},
		},
		selectedOcean: {
			get() {
				return this.$store.selectedOcean
			},
			set(ocean) {
				this.$store.selectedOcean = ocean
			},
		},
		selectedRegion: {
			get() {
				return this.$store.selectedRegion
			},
			set(region) {
				this.$store.selectedRegion = region
			},
		},
	},
	methods: {
		loadStripes() {
			const params = {
				stripeType: this.$store.selectedVariant || 's',
				pH: this.$store.selectedMeasure || 'ph',
			}
			if (this.selectedLmeBasin) {
				params.lmebasin = this.selectedLmeBasin
			}
			if (this.selectedOcean) {
				params.ocean = this.selectedOcean
			}
			if (this.selectedRegion) {
				params.region = this.selectedRegion
			}
			this.$router.push({ name: 'stripes', params })
		},
	},
	watch: {
		localLmeBasin() {
			this.selectedOcean = ''
			this.selectedRegion = ''
		},
		selectedOcean() {
			if (!this.selectedOcean) {
				return
			}
			const oceanObj =
				this.$store.menuTree[this.selectedLmeBasin].children[this.selectedOcean]
			if (oceanObj === undefined) {
				return
			}
			if (
				oceanObj.hasOwnProperty('children') &&
				Object.keys(oceanObj.children).length === 1
			) {
				this.selectedRegion = Object.keys(oceanObj.children)[0]
			} else if (
				oceanObj.hasOwnProperty('children') &&
				oceanObj.children.hasOwnProperty('entirebasin')
			) {
				let needsRefresh = false
				if (this.selectedRegion === 'entirebasin') {
					needsRefresh = true
				}
				this.selectedRegion = 'entirebasin'
				if (needsRefresh) {
					this.loadStripes()
				}
			} else {
				this.selectedRegion = ''
			}
		},
		selectedRegion() {
			if (!this.selectedRegion) {
				return
			}
			// This will always be an endpoint
			this.loadStripes()
		},
	},
}
</script>

<style lang="css" scoped></style>
