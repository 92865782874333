<template lang="html">
	<div class="customContainer">
		<div class="leftPanel hidden-sm-and-down">
			<v-card class="cardBody mb-4">
				<v-card-title class="cardTitle title">
					<v-icon>pin_drop</v-icon>
					<span class="ml-2">Select Region</span>
				</v-card-title>
				<v-divider></v-divider>
				<region-select @endPointReached="regionSelected"></region-select>
			</v-card>

			<v-card class="cardBody" v-if="showStripes">
				<v-card-title class="cardTitle title">
					<v-icon>info</v-icon>
					<span class="ml-2">Information</span>
				</v-card-title>
				<v-divider></v-divider>
				<v-card-text>
					<table>
						<tbody>
							<tr>
								<td class="fieldTitle">Region</td>
								<td>{{ imageInfo.name }}</td>
							</tr>
							<tr>
								<td class="fieldTitle">Date Range</td>
								<td>
									{{ imageInfo.timeRange.from }}-{{ imageInfo.timeRange.to }}
								</td>
							</tr>
							<tr>
								<td class="fieldTitle">Data Source</td>
								<td>
									<a
										href="https://essd.copernicus.org/articles/13/777/2021/"
										target="_blank"
										>OceanSODA-ETHZ</a
									>
									<br v-if="lmes" />
									<a
										v-if="lmes"
										href="https://iwlearn.net/marine/lmes"
										target="_blank"
										>LMEs</a
									>
								</td>
							</tr>
						</tbody>
					</table>
				</v-card-text>
				<v-card-text>
					<table>
						<tbody>
							<tr>
								<td class="fieldTitle">Creator</td>
								<td>
									<a
										target="_blank"
										href="https://usys.ethz.ch/en/people/profile.nicolas-gruber.html"
									>
										Nicolas Gruber </a
									>&amp;
									<a
										target="_blank"
										href="https://up.ethz.ch/people/person-detail.MjU3NTQ5.TGlzdC8xNzUxLC0zMDYxNTA1MjU=.html"
									>
										Luke Gregor
									</a>
								</td>
							</tr>
							<tr>
								<td class="fieldTitle">Licensor</td>
								<td>ETH Zürich</td>
							</tr>
							<tr>
								<td class="fieldTitle">License</td>
								<td>
									<a
										id="licenseLogo"
										target="_blank"
										rel="license"
										href="https://creativecommons.org/licenses/by/4.0/"
									>
										<img
											alt="Creative Commons Licence"
											style="border-width: 0"
											src="https://i.creativecommons.org/l/by/4.0/88x31.png"
										/>
									</a>
								</td>
							</tr>
						</tbody>
					</table>
				</v-card-text>
			</v-card>
		</div>

		<div class="rightPanel">
			<display-stripes
				v-if="showStripes"
				:imageSettings="imageInfo"
			></display-stripes>
			<img v-else class="missing" src="@/assets/img/missing.png" />
			<region-select-small
				@endPointReached="regionSelected"
				class="hidden-md-and-up"
			></region-select-small>
		</div>
	</div>
</template>

<script>
import RegionSelect from './RegionSelect.vue'
import RegionSelectSmall from './RegionSelectSmall.vue'
import DisplayStripes from './DisplayStripes.vue'

export default {
	data() {
		return {
			// waits for the emit
			display_information_box: false,
			media: {},
		}
	},

	computed: {
		imageInfo() {
			const noParams =
				!this.$route.params.lmebasin &&
				!this.$route.params.ocean &&
				!this.$route.params.region
			const lmeBasin = noParams ? 'basin' : this.$route.params.lmebasin
			const lmeBasinObj = this.$store.menuTree[lmeBasin]
			if (!lmeBasinObj) {
				// Menu tree probably hasn't loaded yet
				return {}
			}

			const ocean = noParams ? 'globalocean' : this.$route.params.ocean
			if (ocean && lmeBasinObj.hasOwnProperty('children')) {
				const oceanObj = lmeBasinObj.children[ocean]
				const region = noParams ? 'entirebasin' : this.$route.params.region

				if (region && oceanObj && oceanObj.hasOwnProperty('children')) {
					const regionObj = oceanObj.children[region]
					return regionObj
				} else {
					// This shouldn't ever happen but just in case
					return oceanObj
				}
			} else {
				// This shouldn't ever happen but just in case
				return lmeBasinObj
			}
		},
		showStripes() {
			console.log(this.imageInfo)
			return (
				this.imageInfo &&
				this.imageInfo.hasOwnProperty('ph') &&
				this.imageInfo.hasOwnProperty('omega')
			)
		},
		lmes() {
			return this.$store.selectedLmeBasin === 'lme'
		},
	},
	methods: {
		regionSelected(media) {
			this.display_information_box = media.display
			this.media = media
		},
	},
	components: {
		RegionSelect,
		RegionSelectSmall,
		DisplayStripes,
	},
	beforeRouteUpdate(to, from, next) {
		if (to.params.lmebasin !== this.$store.selectedLmeBasin) {
			this.$store.selectedLmeBasin = to.params.lmebasin || 'basin'
		}
		if (to.params.ocean !== this.$store.selectedOcean) {
			this.$store.selectedOcean = to.params.ocean || 'globalocean'
		}
		if (to.params.region !== this.$store.selectedRegion) {
			this.$store.selectedRegion = to.params.region || 'entirebasin'
		}
		next()
	},
}
</script>

<style lang="css" scoped>
.customContainer {
	width: 100%;
	margin: auto 0;
	display: flex;
	padding: 7vh 0;
}
.leftPanel {
	flex: 3;
	padding: 0 2vw 0 5vw;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
}
.rightPanel {
	flex: 7;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 0 5vw 0 2vw;
}

.rightPanel img.missing {
	width: 100%;
}

.cardTitle {
	background-color: #202020;
}
.cardBody {
	max-width: 420px;
	background-color: #292929;
}
.fieldTitle {
	width: 120px;
}
table {
	width: 100%;
}
td {
	border: 1px solid #535353;
	padding: 3px 12px;
}
.tableTitle {
	text-align: center;
	margin-bottom: 3px;
	font-weight: bold;
	font-size: 15px;
}
a {
	color: #c7f4ff;
	text-decoration: none;
}

a:hover {
	text-decoration: underline;
}
</style>
