<template>
	<v-app dark class="backgroundImage">
		<nav-bar></nav-bar>
		<transition :name="$route.name" mode="out-in">
			<router-view></router-view>
		</transition>

		<foooter></foooter>
		<!-- <sub-footer></sub-footer> -->
	</v-app>
</template>

<script>
import NavBar from './components/NavBar.vue'
import SubFooter from './components/SubFooter.vue'
import Foooter from './components/Footer.vue'
export default {
	name: 'App',
	components: {
		NavBar,
		SubFooter,
		Foooter,
	},
	created() {
		let needToRedirect = false
		if (this.$route.params.lmebasin) {
			this.$store.selectedLmeBasin = this.$route.params.lmebasin
		} else {
			needToRedirect = true
			this.$store.selectedLmeBasin = 'basin'
		}
		if (this.$route.params.ocean) {
			this.$store.selectedOcean = this.$route.params.ocean
		} else {
			needToRedirect = true
			this.$store.selectedOcean = 'globalocean'
		}
		if (this.$route.params.region) {
			this.$store.selectedRegion = this.$route.params.region
		} else {
			needToRedirect = true
			this.$store.selectedRegion = 'entirebasin'
		}
		if (this.$route.params.stripeType) {
			this.$store.selectedVariant = this.$route.params.stripeType
		} else {
			needToRedirect = true
			this.$store.selectedVariant = 's'
		}
		if (this.$route.params.pH) {
			this.$store.selectedMeasure = this.$route.params.pH
		} else {
			needToRedirect = true
			this.$store.selectedMeasure = 'ph'
		}

		if (this.$route.name !== 'faq' && needToRedirect) {
			this.$router.push({
				name: 'stripes',
				params: {
					stripeType: this.$store.selectedVariant,
					lmebasin: this.$store.selectedLmeBasin,
					ocean: this.$store.selectedOcean,
					region: this.$store.selectedRegion,
					pH: this.$store.selectedMeasure,
				},
			})
		}
	},
}
</script>

<style scoped>
.backgroundImage {
	/* Image downloaded from www.subtlepatterns.com */
	/* https://www.toptal.com/designers/subtlepatterns/prism/ */
	background-image: url('@/assets/img/prism.png');
}
.stripes-enter-active,
.stripes-leave-active,
.faq-enter-active,
.faq-leave-active {
	transition: opacity 0.5s, transform 0.5s;
}
.faq-enter,
.stripes-leave-to {
	opacity: 0;
	transform: translateX(3%);
}
.stripes-enter,
.faq-leave-to {
	opacity: 0;
	transform: translateX(-3%);
}
</style>

<style>
a {
	color: teal !important;
	text-decoration: none;
}

a:visited {
	text-decoration: none;
}

a:hover {
	text-decoration: underline;
}

div .primary--text {
	color: #ff6347 !important;
}
</style>

<!-- <style>
#bbox-root {
  display: none;
}
</style> -->
