<template lang="html">
	<v-container>
		<v-layout justify-center wrap>
			<v-flex
				:class="{
					'mt-5': $vuetify.breakpoint.mdAndUp,
					'mt-2': $vuetify.breakpoint.smAndDown,
				}"
				mb-3
				xs12
				text-xs-center
				class="title"
			>
				<v-icon
					:class="{
						heading: $vuetify.breakpoint.mdAndUp,
						smallHeading: $vuetify.breakpoint.smAndDown,
					}"
				>
					question_answer
				</v-icon>
				<span
					:class="{
						heading: $vuetify.breakpoint.mdAndUp,
						smallHeading: $vuetify.breakpoint.smAndDown,
					}"
					>FAQ
				</span>
				<span class="heading ml-0 hidden-sm-and-down">
					: Frequently asked questions
				</span>
			</v-flex>
			<v-flex xs12 md8>
				<v-expansion-panels accordian>
					<v-expansion-panel
						v-model="openState"
						expand
						focusable
						v-for="(item, i) in data"
						:key="i"
					>
						<v-expansion-panel-header>
							<div :style="faqQuestionStyles">
								{{ item.question }}
							</div>
						</v-expansion-panel-header>
						<v-expansion-panel-content>
							<p v-for="line in item.answer" v-html="line"></p>
							<img v-if="item.image" :src="item.image" />
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>
			</v-flex>
		</v-layout>
	</v-container>
</template>

<script>
export default {
	data() {
		return {
			data: [
				{
					question: 'What are these graphics?',
					answer: [
						'These ‘ocean acidification’ graphics are visual representations of the change in ocean acidification as reconstructed from observations over the past 40 years. Each stripe or bar represents either the pH or the aragonite saturation in that region. The stripes start in 1982 and finish in 2022. These ocean acidification stripes are inspired by the well known <a href="https://showyourstripes.info" target="_blank">‘temperature stripes’</a> developed by Ed Hawkins.',
					],
				},
				{
					question: 'What do these graphics show?',
					answer: [
						'The stripes turn from either green to red (for pH) or purple to orange/brown (for aragonite saturation) reflecting the rapid progression of ocean acidification in the past 40 years.',
					],
				},
				{
					question: 'How are these graphics made?',
					answer: [
						'These graphics were made from the global ocean acidification data product OceanSODA-ETHZ (<a href="https://essd.copernicus.org/articles/13/777/2021/" target="_blank">Gregor and Gruber, 2021</a>). This product is based on a combination of ship measurements and satellite data. Machine learning is used to extract relationships between the relatively sparse ship measurements and the global reach of the satellite data. These relationships then allow us to fill the gaps in the ship measurements. For more information on the interpretation see <a href="https://doi.org/10.1029/2023GB007765" target="_blank">Ma et al. (2023)</a>. The latest data can be downloaded from <a href="https://doi.org/10.25921/m5wx-ja34" target="_blank">NOAA NCEI</a>.',
					],
				},
				{
					question: 'What is ocean acidification?',
					answer: [
						'Ocean acidification refers to the sum of all chemical changes in the ocean induced by the ocean taking up a lot of the CO<sub>2</sub> released by the burning of fossil fuels and land use change/deforestation. The chemical changes include an increase of the ocean’s acidity (corresponding to a decreasing pH), a drop in the saturation state of carbonate minerals, and an increase in the oceanic CO<sub>2</sub> concentration.',
					],
				},
				{
					question: 'What is ocean pH?',
					answer: [
						'It is a measure of the acidity of the surface ocean. Beware that the acidity increases with decreasing pH, i.e., the lower the pH, the more acidic seawater is. Scientifically, pH is defined as pH&nbsp;=&nbsp;-log[H+], i.e., the natural log of the concentration of the H+ ions. The changes you see in the plots might seem small, but because pH is on a log scale, the changes are actually very substantial.',
					],
				},
				{
					question: 'What is the aragonite saturation?',
					answer: [
						'It represents the level of saturation of seawater with respect to the calcium carbonate mineral aragonite. When the saturation state, denoted as Ω<sub>ar</sub>, is above 1, waters are supersaturated with regard to this mineral, while when the saturation state Ω<sub>ar</sub> is below 1, the waters are undersaturated, i.e., corrosive. Many organisms require the saturation state to be highly supersaturated in order to precipitate CaCO<sub>3</sub>. For example, warm water corals prefer a saturation state of above 4 and get stressed when it falls below 3. ',
					],
				},
				{
					question: 'Why do we see such uniform decreases?',
					answer: [
						'The most important reason is that the trends in pH and aragonite saturation induced by the steadily increasing atmospheric CO<sub>2</sub> is very strong, especially when compared to the level of their year-to-year variabilities. This variability is particularly weak for pH, while it is larger for the saturation state. This is why we see more variations in the colors for the saturation state.',
					],
				},
				{
					question: 'What are Large Marine Ecosystems?',
					answer: [
						'<a href="https://iwlearn.net/marine/lmes" target="_blank">LMEs</a> are large-scale ocean regions along the Earth’s continental margins and were defined with ecological considerations in mind. OceanSODA-ETHZ does not cover all of these regions. Thus we only show pH and aragonite saturation for regions where more than 80% of the LME is covered by our product.',
					],
				},
				{
					question: 'What can I do with these graphics?',
					answer: [
						'The graphics have a CC-BY4.0 license, so can be used for any purpose as long as credit is given to Nicolas Gruber and Luke Gregor (ETH Zürich) and a link is provided to this website.',
					],
				},
			],
			openState: [false],
		}
	},
	computed: {
		faqQuestionStyles() {
			return `font-size: ${this.$vuetify.breakpoint.mdAndUp ? 20 : 14}px;
        padding: ${this.$vuetify.breakpoint.mdAndUp ? 8 : 0}px;
        font-weight: 500;`
		},
		faqAnswerStyles() {
			return `padding: ${this.$vuetify.breakpoint.mdAndUp ? 25 : 16}px;
        font-size: ${this.$vuetify.breakpoint.mdAndUp ? 16 : 12}px;`
		},
	},
	beforeRouteUpdate(to, from, next) {
		if (to.params.lmebasin !== this.$store.selectedLmeBasin) {
			this.$store.selectedLmeBasin = to.params.lmebasin || 'basin'
		}
		if (to.params.ocean !== this.$store.selectedOcean) {
			this.$store.selectedOcean = to.params.ocean
		}
		if (to.params.region !== this.$store.selectedRegion) {
			this.$store.selectedRegion = to.params.region
		}
		next()
	},
}
</script>
<style lang="css" scoped>
.container {
	height: 100%;
}

.title {
	text-align: center;
}

.heading {
	font-size: 24px;
	font-weight: 500;
}
.smallHeading {
	font-size: 20px;
	font-weight: 500;
	margin-left: 5px;
}

p {
	text-align: justify;
}

p:first-child::before {
	content: '»';
	font-weight: bold;
	font-size: 250%;
	line-height: 100%;
	color: teal;
	margin: 0 0.5rem 0 0;
	vertical-align: sub;
}

img {
	max-width: 100%;
	margin: auto;
	display: block;
}

.wsite-form-field div.wsite-form-input-container .wsite-form-input {
	box-sizing: border-box;
	width: 100% !important;
}

.wsite-form-input,
.wsite-search-element-input {
	margin-bottom: 10px;
	padding: 10px !important;
	background: #fafafa;
	color: #444444;
	border: 1px solid #b9b9b9;
	border-radius: 1px;
	font-family: 'Quattrocento Sans', sans-serif;
	font-size: 14px;
	font-weight: 400;
	line-height: normal;
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.wsite-form-label {
	display: inline-block;
	padding: 10px 0 5px;
	color: #333333;
	font-family: 'Quattrocento Sans', sans-serif;
	font-size: 16px;
	font-weight: normal;
	line-height: normal;
}

.wsite-form-label.wsite-form-fields-required-label {
	font-weight: normal;
	font-style: italic;
}

.form-required {
	display: inline;
	color: #ff2825 !important;
}

.submit-button {
	width: 100%;
	font-family: 'Roboto', sans-serif;
	padding: 0.5rem;
	box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
		0 1px 5px 0 rgba(0, 0, 0, 0.12);
	background-color: rgb(245, 245, 245);
}

.submit-button:disabled {
	background-color: rgba(0, 0, 0, 0.12);
	color: rgba(0, 0, 0, 0.26);
}
</style>
