<template lang="html">
	<div>
		<v-navigation-drawer
			app
			v-model="drawerOpen"
			v-if="$vuetify.breakpoint.smAndDown"
		>
			<v-list three-line subheader>
				<v-subheader>Menu</v-subheader>
				<template v-for="item in menuItems">
					<v-list-item :to="item.route" :key="item.name">
						<v-list-item-content>
							<v-list-item-title>{{ item.title }}</v-list-item-title>
							<v-list-item-subtitle>{{ item.desc }}</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
					<v-divider :key="item.name"></v-divider>
				</template>
			</v-list>
		</v-navigation-drawer>
		<v-toolbar :height="nav_bar_height">
			<v-divider vertical v-if="$vuetify.breakpoint.mdAndUp"> </v-divider>
			<v-app-bar-nav-icon
				v-if="$vuetify.breakpoint.smAndDown"
				@click="drawerOpen = !drawerOpen"
			>
			</v-app-bar-nav-icon>
			<v-toolbar-title
				:class="{
					'ml-0': $vuetify.breakpoint.smAndDown,
					'ml-3': $vuetify.breakpoint.mdAndUp,
				}"
			>
				<router-link
					class="font-weight-medium"
					:class="{
						title: $vuetify.breakpoint.lgAndDown,
						'display-1': $vuetify.breakpoint.lgAndUp,
					}"
					to="/"
					tag="button"
				>
					{{ appTitle }}
				</router-link>
			</v-toolbar-title>
			<v-spacer></v-spacer>
			<v-toolbar-items class="hidden-sm-and-down">
				<template v-for="item in menuItems">
					<v-btn
						text
						:to="item.route"
						:key="item.name"
						:class="{
							title: $vuetify.breakpoint.lgAndUp,
							subheading: $vuetify.breakpoint.lgAndDown,
						}"
					>
						{{ item.title }}
					</v-btn>
					<v-divider vertical :key="item.name"> </v-divider>
				</template>
				<v-btn
					text
					href="https://showyourstripes.info"
					:class="{
						title: $vuetify.breakpoint.lgAndUp,
						subheading: $vuetify.breakpoint.lgAndDown,
					}"
				>
					Climate Stripes
				</v-btn>
				<v-divider vertical> </v-divider>
			</v-toolbar-items>
			<a target="_blank" rel="noopener" href="https://www.up.ethz.ch/">
				<img
					alt="ETH logo"
					class="mx-3 mt-2 logo"
					src="@/assets/img/ethz_logo.svg"
				/>
			</a>
		</v-toolbar>
	</div>
</template>

<script>
export default {
	name: 'AppNavigation',
	data() {
		return {
			appTitle: '#OceanAcidificationStripes',
			menuItems: [
				{
					title: 'Home',
					desc: 'Check ocean acidification trends around the globe',
					route: '/',
				},
				{
					title: 'FAQ',
					desc: 'Frequently asked Questions',
					route: '/faq',
				},
			],
			drawerOpen: false,
		}
	},
	computed: {
		nav_bar_height() {
			switch (this.$vuetify.breakpoint.name) {
				case 'xs':
				case 'sm':
				case 'md':
				case 'lg':
					return 60
				case 'xl':
					return 80
				default:
					return 60
			}
		},
	},
}
</script>

<style lang="css" scoped>
.v-list a:hover {
	text-decoration: none;
}

a {
	height: 100%;
	max-height: 100%;
	margin: none;
}
a img.logo {
	margin: 0 !important;
	height: 100%;
	max-height: 100%;
	padding: 10px 0 10px 10px;
}

@media (max-width: 480px) {
	a img.logo {
		padding: 16px 0 16px 16px;
	}

	.v-application .title {
		font-size: 1rem !important;
	}
}

@media (max-width: 380px) {
	a img.logo {
		padding: 20px 0 20px 20px;
	}

	.v-application .title {
		font-size: 0.8rem !important;
	}
}
</style>
