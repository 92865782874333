var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-footer',{class:{
		'pa-4': _vm.$vuetify.breakpoint.mdAndUp,
		'pt-1': _vm.$vuetify.breakpoint.smAndDown,
	},attrs:{"height":_vm.footer_height}},[_c('v-layout',{attrs:{"row":"","wrap":"","align-center":""}},[_c('v-flex',{attrs:{"md5":"","xs12":""}},[_c('div',{class:{
					'text-left body-1 ml-3': _vm.$vuetify.breakpoint.mdAndUp,
					'text-center body-1': _vm.$vuetify.breakpoint.smAndDown,
					'text-left caption': _vm.$vuetify.breakpoint.lg,
				}},[_c('span',{staticClass:"key hidden-sm-and-down"},[_vm._v("Graphics and lead scientists: ")]),_c('a',{attrs:{"target":"_blank","href":"https://usys.ethz.ch/en/people/profile.nicolas-gruber.html"}},[_vm._v(" Nicolas Gruber ")]),_vm._v("& "),_c('a',{attrs:{"target":"_blank","href":"https://up.ethz.ch/people/person-detail.MjU3NTQ5.TGlzdC8xNzUxLC0zMDYxNTA1MjU=.html"}},[_vm._v(" Luke Gregor ")]),_c('span',{staticClass:"value hidden-lg-and-down"},[_vm._v(", "),_c('a',{attrs:{"href":"https://up.ethz.ch/","target":"_blank"}},[_vm._v("ETH Zürich")]),_vm._v(".")])]),_c('div',{class:{
					'text-left ml-3': _vm.$vuetify.breakpoint.mdAndUp,
					'text-center': _vm.$vuetify.breakpoint.smAndDown,
					'text-left caption': _vm.$vuetify.breakpoint.lg,
				}},[_c('span',{staticClass:"key"},[_vm._v("Data: ")]),_c('span',[_c('a',{attrs:{"href":"https://essd.copernicus.org/articles/13/777/2021/","target":"_blank"}},[_vm._v("OceanSODA-ETHZ")])])])]),_c('v-flex',{attrs:{"md5":"","offset-md2":"","xs-12":""}},[_c('div',{class:{
					'text-right subheading mr-4': _vm.$vuetify.breakpoint.mdAndUp,
					'text-center subheading': _vm.$vuetify.breakpoint.smAndDown,
					'text-right caption': _vm.$vuetify.breakpoint.lg,
				}},[_c('span',{staticClass:"key"},[_vm._v("Website: ")]),_c('a',{attrs:{"target":"_blank","href":"https://www.the-iea.org/"}},[_c('img',{staticClass:"iea_favicon",attrs:{"src":require("@/assets/IEA_favicon.png"),"height":"30","alt":"Institute for Environmental Analytics logo"}}),_c('span',{staticClass:"hidden-sm-and-down"},[_vm._v("Institute for Environmental Analytics")]),_c('span',{staticClass:"hidden-md-and-up"},[_vm._v("IEA")])])]),_c('div',{class:{
					'text-right subheading mr-4': _vm.$vuetify.breakpoint.mdAndUp,
					'text-center subheading': _vm.$vuetify.breakpoint.smAndDown,
					'text-right caption': _vm.$vuetify.breakpoint.lg,
				}},[_c('span',{staticClass:"key"},[_vm._v("Website funding: ")]),_c('a',{attrs:{"target":"_blank","href":"https://www.up.ethz.ch/"}},[_c('img',{staticClass:"iea_favicon",attrs:{"src":require("@/assets/img/ethz_logo.svg"),"height":"26","alt":"ETH Zürich logo"}})])])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }